import { Component, inject, Input, OnInit } from '@angular/core';
import { Customer } from "../../../../model/models";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { CommonModule } from "@angular/common";
import { getDataAttributesColumns } from "./data-attributes-table-columns";
import { CustomersService } from "../../../../services/customers/customers.service";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule } from "@angular/forms";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { VariablesService } from "../../../../services/variables/variables.service";
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AttrFilterByPipe } from '../../../../pipes/attr-filter-by.pipe';

@Component({
  selector: 'customer-data-attributes',
  standalone: true,
  imports: [NzTableModule, CommonModule, NzCollapseModule, NzCheckboxModule, FormsModule, NzSpinComponent, NzButtonModule, NzInputModule, NzIconModule, AttrFilterByPipe],
  templateUrl: './customer-data-attributes.component.html',
  styleUrl: './customer-data-attributes.component.scss'
})
export class CustomerDataAttributesComponent implements OnInit {
  @Input() customer!: Customer
  customersService = inject(CustomersService);
  variablesService = inject(VariablesService)
  notificationService = inject(NzNotificationService)
  isLoading = false;
  private inputTimeout: any;
  columnDefs: any
  selectedRows: any[] = [];
  searchInputValue: any

  ngOnInit() {
    this.columnDefs = getDataAttributesColumns();
    this.isLoading = true;

    this.variablesService.getStandardVariableNames('de').subscribe(res => {
      const columnsMap = res.ExpertView.Table.Columns;

      this.customersService.getDataAttributes(this.customer.pk).subscribe(res => {
        const attributesMap = new Map(
          res.map((item: { attribute: any; enabled: any }) => [item.attribute, item.enabled])
        );

        this.columnDefs.forEach((table: { children: any[] }) => {
          table.children.forEach(child => {
            if (columnsMap[child.field]) {
              child.headerName = columnsMap[child.field].name;
            }
            child.selected = attributesMap.get(child.field) ?? false;
          });
        });

        this.isLoading = false;
      });

      this.columnDefs.forEach((column: { allSelected: boolean; children: any[] }) => {
        column.allSelected = false;
        column.children.forEach((row: any) => (row.selected = false));
      });
    });
  }

  toggleSelectAll(column: any): void {
    column.children.forEach((row: any) => (row.selected = column.allSelected));
    this.updateSelectedRows();
  }

  onRowSelect(column: any): void {
    column.allSelected = column.children.every((row: any) => row.selected);
    if (!column.allSelected) {
      column.allSelected = false;
    }
    this.updateSelectedRows();
  }

  updateSelectedRows(): void {
    this.selectedRows = [];
    this.columnDefs.forEach((column: { children: any[]; }) => {
      column.children.forEach((row: any) => {
        if (row.selected) {
          this.selectedRows.push(row.field);
        }
      });
    });
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.setDataAttributes();
    }, 500);
  }

  setDataAttributes(): void {
    this.customersService.setDataAttributes(this.customer.pk, this.selectedRows).subscribe({
      error: err => {
        this.notificationService.error('Error', 'Please, Reload the page')
      }
    })
  }

  getPanelHeader(column: any): string {
    const selectedCount = column.children.filter((row: any) => row.selected).length;
    return `${column.headerName} (${selectedCount} / ${column.children.length})`;
  }

  setSearch(input: any) {
    this.searchInputValue = input.value
  }

  clearSearch() {
    this.searchInputValue = ''
  }

}
