<div class="header-container">
  <h2 class="header-title">Variable Standard Names</h2>
  <search-input
    [placeholder]="'Search variable'"
    (searchValue)="handleSearchClick($event)"
  ></search-input>
</div>

<div class="loader" *ngIf="isLoading">
  <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading..."></nz-spin>
</div>

<nz-collapse *ngIf="!isLoading">
  @for (column of this.columnDefsData; track $index) {
    <nz-collapse-panel
      [nzHeader]="column.headerName"
    >
      <nz-table
        #basicTable
        [nzData]="column.children"
        [nzBordered]="true"
        [nzSize]="'middle'"
        [nzFrontPagination]="false"
      >
        <thead>
        <tr>
          <th>Variable</th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.field }}</td>
          <td>
            <input
              style="width: 550px"
              nz-input
              placeholder="New Value"
              type="text"
              [(ngModel)]="data.headerName"
              (ngModelChange)="onInputChange(data.field, $event)"
            />
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-collapse-panel>
  }
</nz-collapse>
