import { Component, inject, OnInit } from '@angular/core';
import { VariablesService } from "../../services/variables/variables.service";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { getDataAttributesColumns } from "../customers/customer/customer-data-attributes/data-attributes-table-columns";
import { CommonModule } from "@angular/common";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import {SearchInputComponent} from "../search-input/search-input.component";
import { FormsModule } from "@angular/forms";
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'variable-standard-names',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzTableModule, NzInputModule, FormsModule, NzCollapseModule, SearchInputComponent],
  templateUrl: './variable-standard-names.component.html',
  styleUrl: './variable-standard-names.component.scss'
})
export class VariableStandardNamesComponent implements OnInit {
  standardVariableNamesService = inject(VariablesService);
  notificationService = inject(NzNotificationService)
  translations: any;
  columnDefsData: any;
  originalData: any[] = [];
  isLoading = false;
  private inputTimeout: any;

  ngOnInit() {
    this.isLoading = true;
    const groupedColumns = getDataAttributesColumns();
    this.standardVariableNamesService.getStandardVariableNames('de').subscribe(res => {
      this.translations = res;

      this.columnDefsData = groupedColumns.map(group => ({
        ...group,
        children: group.children.sort((a, b) => a.field.localeCompare(b.field)).map(child => ({
          ...child,
          headerName: this.translations.ExpertView.Table.Columns[child.field]?.name || child.headerName
        }))
      }));
      this.originalData = JSON.parse(JSON.stringify(this.columnDefsData));

      this.isLoading = false;
    });
  }

  onInputChange(field: string, value: string) {
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      if (!this.translations.ExpertView.Table.Columns[field]) {
        this.translations.ExpertView.Table.Columns[field] = { name: value };
      } else {
        this.translations.ExpertView.Table.Columns[field].name = value;
      }

      this.columnDefsData.forEach((group: { children: any[]; }) => {
        group.children.forEach(child => {
          if (child.field === field) {
            child.headerName = value;
          }
        });
      });

      this.save();
    }, 500);
  }

  save() {
    this.standardVariableNamesService.setStandardVariableNames(this.translations, 'de').subscribe({
      error: () => {
        this.notificationService.error('Error', 'Please, Reload the page')
      }
    });
  }

  handleSearchClick(value: string): void {
    if (!value) {
      this.columnDefsData = JSON.parse(JSON.stringify(this.originalData));
      return;
    }
    const searchValue = value.toLowerCase();
    this.columnDefsData = this.originalData
      .map((group: { groupID: string; headerName: string; children: any[] }) => {
        const filteredChildren = group.children.filter((child: { field: string }) =>
          child.field.toLowerCase().includes(searchValue)
        );
        return filteredChildren.length > 0 ? { ...group, children: filteredChildren } : null;
      })
      .filter((group): group is { groupID: string; headerName: string; children: any[] } => group !== null);
  }

}
