
<div class="users-header-container">
  <h3 class="header-title">Users</h3>
  <button class="primary-button" nz-button nzSize="default" nzType="primary" nzDanger (click)="openCreateCustomersUserModal(tplContent, tplFooter)">
    <span nz-icon nzType="plus" nzTheme="outline"></span>
    Add new user
  </button>
</div>
<nz-table
  [nzLoading]="isLoading"
  [nzData]="users"
  [nzShowPagination]="false"
  [nzFrontPagination]="false"
  [nzSize]="'small'"
>
  <thead>
  <tr>
    <th>Name</th>
    <th>Email</th>
    <th>Created at</th>
    <th>Last visit</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users">
    <td>{{ user.name}}</td>
    <td>{{ user.email }}</td>
    <td>{{ user.created_at }}</td>
    <td>{{ user.last_visit }}</td>
    <td>
      @if(customer.admin_email === user.email) {
        <div class="crown">
          <img src="assets/crown.svg">
        </div>
      } @else {
        <button nz-button nzType="text" (click)="deleteUser(user)">
          <span nz-icon nzType="delete"></span>
        </button>
      }
    </td>
  </tr>
  </tbody>
</nz-table>


<ng-template #tplContent>
  <form nz-form [formGroup]="userForm">
    <nz-form-label class="feature-form-label" nzRequired>User name</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Name is required">
        <input nz-input formControlName="name" name="user_name" required="true" placeholder="Name"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-label class="feature-form-label" nzRequired>User email</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Email is required">
        <input nz-input formControlName="email" name="user_email" required="true" placeholder="Email"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-label class="feature-form-label" nzRequired>User role</nz-form-label>
    <nz-form-item>
      <nz-select nzPlaceHolder="Role" formControlName="role_id" required="true" style="width: 100%;">
        @for (role of roles; track $index){
          <nz-option [nzLabel]="role.name" [nzValue]="role.role_id"></nz-option>
        }
      </nz-select>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="handleModalClose(ref)" class="form-button">Cancel</button>
  <button
    [disabled]="userForm.invalid"
    nzType="primary"
    [nzLoading]="isCreatingUserLoading"
    nz-button
    class="form-button"
    nzDanger
    (click)="save(ref)"
    >
    Create
  </button>
</ng-template>
