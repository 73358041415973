import { Component, inject, Input, OnInit, TemplateRef } from '@angular/core';
import { Customer } from "../../../model/models";
import { CustomersService } from "../../../services/customers/customers.service";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { CommonModule } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzIconDirective, NzIconModule } from "ng-zorro-antd/icon";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormControlComponent, NzFormDirective, NzFormItemComponent, NzFormLabelComponent } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'customer-users',
  standalone: true,
  imports: [NzTableModule, CommonModule, NzButtonModule, NzIconDirective, FormsModule, NzFormControlComponent, NzFormDirective, NzFormItemComponent, NzFormLabelComponent, NzInputModule, ReactiveFormsModule, NzSelectModule, NzIconModule],
  templateUrl: './customer-users.component.html',
  styleUrl: './customer-users.component.scss'
})
export class CustomerUsersComponent implements OnInit {
  @Input() customer!: Customer

  users: any[] = [];
  isLoading = true;
  userForm!: FormGroup;
  roles: any
  isCreatingUserLoading = false

  customerService = inject(CustomersService);
  notificationService = inject(NzNotificationService);
  modal = inject(NzModalService);

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role_id: ['', Validators.required],
    });
    this.getUsers()
    this.customerService.getCustomersRoles().subscribe(res => {
      this.roles = res;
    })
  }

  getUsers() {
    this.customerService.getUsers(this.customer.pk).subscribe(res => {
      this.users = res;
      this.isLoading = false
    }, error => {
      this.isLoading = false
      this.notificationService.error('Error', 'Please, Reload the page')
    })
  }

  openCreateCustomersUserModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    const modal: NzModalRef = this.modal.create({
      nzClassName: 'customer-users-modal',
      nzTitle: 'Create User',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzOnCancel: () => this.handleModalClose()
    });
  }

  save(ref: any) {
    this.isCreatingUserLoading = true;

    this.customerService.createCustomerUser(this.customer.pk, this.userForm.value)
      .subscribe({
        next: (res) => {
          this.isCreatingUserLoading = false;
          this.userForm.reset();
          ref.destroy();
          this.getUsers()
        },
        error: (err) => {
          this.isCreatingUserLoading = false;
          this.notificationService.error('Error', 'Failed to create user');
        }
      });
  }

  handleModalClose(ref?: any) {
    this.userForm.reset()
    if (ref) {
      ref.destroy()
    }
  }

  deleteUser(user: any) {
    const notificationService = this.notificationService
    this.modal.warning({
      nzTitle: `Delete user ${user.name}`,
      nzContent: `User ${user.name} will be deleted permanently`,
      nzOkText: 'Yes',
      nzOkDanger: true,
      nzCancelText: 'No',
      nzOnOk: () => new Promise(resolve => {
        this.customerService.deleteCustomerUser(this.customer.pk, user.id).subscribe({
          next: () => {
            this.getUsers()
            notificationService.success('Success', `User ${user.name} deleted`)
          },
          error(err: Error) {
            notificationService.error('Error', `There was an error ${err.message}`)
          },
          complete: () => resolve()
        })
      })
    })
  }
}
