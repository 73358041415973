import {Component, OnInit, inject, TemplateRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzModalModule, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FeatureComponent} from "./feature/feature.component";
import {FeaturesService} from "../../services/features/features.service";
import {GoogleLoginProvider, SocialAuthService} from "@abacritt/angularx-social-login";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import {SearchInputComponent} from "../search-input/search-input.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'features',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzFormModule, FormsModule, NzModalModule, FeatureComponent, NzSpinModule, NzIconModule, NzInputModule, SearchInputComponent, ReactiveFormsModule, NzCheckboxModule],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss'

})
export class FeaturesComponent implements OnInit {
  // mockService = inject(MockDataService);
  modal = inject(NzModalService);
  featureService = inject(FeaturesService);
  notificationService = inject(NzNotificationService)
  listOfFeatures: any[] = [];
  filteredListOfFeatures: any[] = [];
  feature: any = {};
  featureEditId: any = "";
  featureDeleteId: any = "";
  isLoading = false;
  authService = inject(SocialAuthService);
  searchValue: string = "";
  deleteTextValue = "";
  deleteText = "delete";

  featureForm!: FormGroup;
  initialFormValues: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.featureForm = this.fb.group({
      name: ['', Validators.required],
      alias: ['', Validators.required],
      description: ['', Validators.required],
      price: ['', Validators.required],
      renewal_period: ['', Validators.required],
      name_subtitle: ['', Validators.required],
      pricing_information: [''],
      show_on_frontpage: [false],
    });
    this.getFeatures();
  }

  getFeatures() {
    this.isLoading = true;
    this.featureService.getFeatures().subscribe(res => {
      this.listOfFeatures = res
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.notificationService.error('Error', 'Please, Reload the page')
    })
  }

  editFeature(featureEditId: any, feature: any) {
    this.isLoading = true;
    this.featureService.editFeature(featureEditId, feature).subscribe(res => {
      this.feature = {};
      this.featureEditId = ""
      this.isLoading = false
      this.getFeatures();
    })
  }

  saveFeature(modalRef: NzModalRef) {
    this.feature = this.featureForm.value;
    if (this.featureEditId) {
      this.editFeature(this.featureEditId, this.feature)
    } else {
      this.featureService.createNewFeature(this.feature).subscribe(res => {
        this.feature = {};
        this.getFeatures();
      });
    }
    modalRef.close()
  }

  openFeatureModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, featureEditId?: any): void {
    if (featureEditId) {
      this.featureEditId = featureEditId;
      this.feature = this.listOfFeatures.find(feature => {
        return feature.pk === featureEditId;
      })
      this.featureForm.patchValue(this.feature);
      const modal: NzModalRef = this.modal.create({
        nzClassName: 'feature-modal',
        nzTitle: 'Edit Feature',
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzOnCancel: () => this.handleModalClose()
      });

    } else {
      this.featureForm.patchValue({
        name: "",
        alias: "",
        description: "",
        price: "",
        renewal_period: "",
        name_subtitle: "",
        pricing_information: ""
      });
      const modal: NzModalRef = this.modal.create({
        nzClassName: 'feature-modal',
        nzTitle: 'Add new feature',
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
      });
    }
  }

  openDeleteFeatureModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, featureDeleteId?: any): void {
    if(featureDeleteId){
      this.featureDeleteId = featureDeleteId;
      this.feature = this.listOfFeatures.find(feature => {
        return feature.pk === featureDeleteId;
      })
      this.deleteText = ("delete " + this.feature.name).trim()
    }
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Delete Feature',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzOnCancel: () => this.handleModalClose()
    });
  }

  deleteFeature(ref: any){
    this.isLoading = true
    this.featureService.deleteFeature(this.featureDeleteId).subscribe(res => {
      this.getFeatures();
      this.isLoading = false;
    });
    ref.destroy()
  }

  handleModalClose(ref?: any) {
    if (ref) {
      ref.destroy()
    }
    // this.getFeatures();
    this.featureEditId = "";
    this.feature = {};
    this.deleteTextValue = "";
    this.deleteText = "";
    this.featureForm.reset();
  }

  handleSearchClick(searchValue: string) {
    this.searchValue = searchValue;
    if (this.searchValue) {
      this.filteredListOfFeatures = this.listOfFeatures.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    }
  }
}
