<div class="header-container">
  <span nz-typography><strong>Filters</strong></span>
  <div class="header-actions-container">
    <button
      nz-button
      nzType="primary"
      nzDanger
      (click)="createFilterModal(addFilter, addFilterFooter)"
      >
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Add filter
    </button>
  </div>
</div>
<p class="divider">
  <span class="text">Filter</span
  ><span class="line"></span>
</p>
<nz-skeleton [nzActive]="true" *ngIf="isLoading"></nz-skeleton>
<div class="filter filter-wrap" >
  @if(!emptyFilter()) {
    <div class="filter-box filter-wrap">
      @for (item of filter?.filter|keyvalue; track $index) {
        <div class="filter-name">{{ mappings[item.key] }}:
          @for (value of item.value; track $index) {
            <ng-content *ngTemplateOutlet="deleteTag == value ? processing : tag">
            </ng-content>
            <ng-template #tag>
              <nz-tag
              class="filter-tag"
              [nzMode]="'closeable'"
              (nzOnClose)="deleteFilter($event, value, item.key)"
              >{{ value }}</nz-tag>
            </ng-template>
            <ng-template #processing>
              <nz-tag class="filter-tag" nzColor="red">
                <span nz-icon nzType="sync" nzSpin></span>
                <span>processing</span>
              </nz-tag>
            </ng-template>
          }
        </div>
      }
    </div>
  } @else {
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  }

</div>
<div class="preview-content">
  <nz-table
    [nzLoading]="vehiclesPreviewLoading"
    [nzData]="vehicleResults"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzSize]="'small'"
  >
    <thead>
    <tr>
      <th>Order</th>
      <th>Kunde</th>
      <th>Kunden ID</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of vehicleResults">
      <td>
        <span>
          <img src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{item.vehicle_brand}}&modelFamily={{item.vehicle_model}}&angle=28&zoomType=fullscreen&paintdescription={{text2Slug(item.vehicle_color)}}" width="80"/>
        </span>
        <span style="margin-left: 6px;">
          {{ item.vehicle_model }}
        </span>
      </td>
      <td>{{ item.cardealer_customer_name }}</td>
      <td>{{ item.cardealer_customer_id }}</td>
    </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #addFilter>
  <form nz-form>
    <nz-form-item>
      <nz-form-label nzRequired>Filter property</nz-form-label>
      <nz-form-control>
        <nz-select
          name="filterKey"
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select property"
          (ngModelChange)="selectFilterProp($event)"
          [compareWith]="compareFn"
          [nzFilterOption]="nzFilterOption"
          [(ngModel)]="selectedProp"
        >
          @for (item of mappings | keyvalue: zero; track $index) {
          <nz-option nzCustomContent [nzLabel]="item.value" [nzValue]="item">
            <div class="mapping-option-item">
              <span nz-typography>{{item.value}}</span>
              <span nz-typography nzType="secondary">{{item.key}}</span>
            </div>
          </nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    @if (editFilter && selectedProp) {
    <nz-form-label>Filter data</nz-form-label>
    <div class="filter-box filter-wrap">
      <div class="filter-box-label" style="padding-right: 6px">
        {{ selectedProp.value }}:
      </div>
      @for (item of editFilter[selectedProp.key]; track $index) {
      <nz-tag
        class="filter-tag"
        [nzMode]="'closeable'"
        (nzOnClose)="deleteFilter($event, item)"
        >{{ item }}</nz-tag>
      }
    </div>
    <nz-form-item>
      <nz-form-label nzRequired>Filter value</nz-form-label>
      <nz-form-control>
        <nz-input-group nzSearch [nzAddOnAfter]="addInput">
          <input nz-input name="addFilterValue" #input />
        </nz-input-group>
        <ng-template #addInput>
          <button
            nz-button
            nzType="default"
            [disabled]="!input.value"
            (click)="addFilterValue(input)"
          >
            <span nz-icon nzType="plus"></span>
          </button>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    }
  </form>
</ng-template>
<ng-template #addFilterFooter let-ref="modalRef">
  <button nz-button (click)="closeModal(ref)">Cancel</button>
  <button
    nz-button
    [nzLoading]="isCRUD"
    [disabled]="isDisabled()"
    (click)="saveFilter(ref)"
    nzType="primary"
    nzDanger
  >
    Add filter
  </button>
</ng-template>
<ng-template #tplDeleteModal>
  <p>
    Wenn Sie eine Großkunde löschen, werden alle Einstellungen entfernt und der
    Benutzerzugriff wird gesperrt. Die monatliche Gebühr für diese Ansicht wird
    für den nächsten Abrechnungszyklus entfernt.
  </p>
  <p nz-typography nzType="danger">
    <strong>Dies kann nicht rückgängig gemacht werden.</strong>
  </p>
</ng-template>


