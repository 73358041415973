
export function getDataAttributesColumns(){
  return [
    {
      headerName: 'Adressdaten', groupId: "adressdaten", children: [
        { field: "admission_service_location", headerName: "admission_service_location" },
        { field: "admission_service_name", headerName: "Zulassungsdienst" },
        { field: "admission_service_street", headerName: "admission_service_street" },
        { field: "admission_service_street_number", headerName: "admission_service_street_number" },
        { field: "admission_service_vehicle_document_shipping_service_name", headerName: "Versand-dienstleister" },
        { field: "admission_service_vehicle_registration_office", headerName: "Straßenverkehrsamt" },
        { field: "admission_service_zip_code", headerName: "admission_service_zip_code" },
        { field: "assembler_location", headerName: "assembler_location" },
        { field: "assembler_name", headerName: "Dienstleister für Ein- und Aufbau", headerTooltip: "Einem Auftrag zugeordneter Dienstleistername für Ein- und Aufbau-Dienstleistungen." },
        { field: "assembler_street", headerName: "assembler_street" },
        { field: "assembler_street_number", headerName: "assembler_street_number" },
        { field: "assembler_zip_code", headerName: "assembler_zip_code" },
        {
          field: "cardealer_name",
          headerName: "Bestellendes Autohaus",
          headerTooltip:
            "Name des Autohauses, welches für die Auftragsbestellung verantwortlich ist.",

        },
        {
          field: "cardealer_shipping_address_name",
          headerName: "Zieladresse lt. Autohaussystem",
          headerTooltip: "Vor Auslieferung in den Systemen hinterlegte Zieladresse.",
        },
        {
          field: "cardealer_shipping_address_location",
          headerName: "Ort Zieladresse",
        },
        {
          field: "cardealer_shipping_address_street",
          headerName: "Straße Zieladresse",
        },
        {
          field: "cardealer_shipping_address_street_number",
          headerName: "Hausnr. Zieladresse",
        },
        {
          field: "cardealer_shipping_address_zip_code",
          headerName: "PLZ Zieladresse",
        },
        {
          field: "cardealer_street",
          headerName: "Straße bestellendes Autohaus",
          headerTooltip:
            "Straße des Autohauses, welches für die Auftragsbestellung verantwortlich ist.",
        },
        {
          field: "cardealer_street_number",
          headerName: "Hausnr. bestellendes Autohaus",
          headerTooltip:
            "Hausnr. des Autohauses, welches für die Auftragsbestellung verantwortlich ist.",
        },
        {
          field: "cardealer_zip_code",
          headerName: "PLZ bestellendes Autohaus",
          headerTooltip:
            "PLZ des Autohauses, welches für die Auftragsbestellung verantwortlich ist.",
        },
        {
          field: "current_shipping_address_location",
          headerName: "Ort Zieladresse",
        },
        {
          field: "current_shipping_address_name",
          headerName: "Aktuelle Zieladresse",
          headerTooltip: "Vor Auslieferung in den Systemen hinterlegte Zieladresse.",
        },
        {
          field: "current_shipping_address_street",
          headerName: "Straße Zieladresse",
        },
        {
          field: "current_shipping_address_street_number",
          headerName: "Hausnr. Zieladresse",
        },
        {
          field: "current_shipping_address_zip_code",
          headerName: "PLZ Zieladresse",
        },
        {
          field: "lessor_name",
          headerName: "Leasinggesellschaft",
          headerTooltip: "Leasinggesellschaft",

        },
        {
          field: "cardealer_location",
          headerName: "Ort bestellendes Autohaus",
          headerTooltip:
            "Ort des Autohauses, welches für die Auftragsbestellung verantwortlich ist.",
        },
        {
          field: "cardealer_sales_address_name",
          headerName: "Auftragsdurchführendes Autohaus",
          headerTooltip:
            "Name des Autohauses, welches für die Auftragsdurchführung verantwortlich ist.",
        },
        {
          field: "cardealer_sales_address_street",
          headerName: "Straße durchführendes Autohaus",
          headerTooltip:
            "Straße des Autohauses, welches für die Auftragsdurchführung verantwortlich ist.",
        },
        {
          field: "cardealer_sales_address_street_number",
          headerName: "Hausnr. durchführendes Autohaus",
          headerTooltip:
            "Hausnr. des Autohauses, welches für die Auftragsdurchführung verantwortlich ist.",
        },
        {
          field: "cardealer_sales_address_zip_code",
          headerName: "PLZ durchführendes Autohaus",
          headerTooltip:
            "PLZ des Autohauses, welches für die Auftragsdurchführung verantwortlich ist.",
        },
        {
          field: "cardealer_sales_address_location",
          headerName: "Ort durchführendes Autohaus",
          headerTooltip:
            "Ort des Autohauses, welches für die Auftragsdurchführung verantwortlich ist.",
        },
        {
          field: 'yoma_pdi_location',
          headerName: 'Logistikzentrum',
          headerTooltip: 'Ort, von dem aus die weitere Prozessschritte koordiniert werden',
        },
        { field: 'lessor_location', headerName: 'lessor_location' },
        { field: 'lessor_street', headerName: 'lessor_street' },
        { field: 'lessor_street_number', headerName: 'lessor_street_number' },
        { field: 'lessor_zip_code', headerName: 'lessor_zip_code' },
        { field: "onlinesales_dealer_location", headerName: "onlinesales_dealer_location" },
        { field: "onlinesales_dealer_name", headerName: "onlinesales_dealer_name" },
        { field: "onlinesales_dealer_street", headerName: "onlinesales_dealer_street" },
        { field: "onlinesales_dealer_street_number", headerName: "onlinesales_dealer_street_number" },
        { field: "onlinesales_dealer_zip_code", headerName: "onlinesales_dealer_zip_code" },
      ]
    },
    {
      headerName: 'Bemerkungen', groupId: "bemerkungen", marryChildren: true, children: [
        {
          field: "cardealer_notes_1",
          headerName: "Bemerkung Herstellersystem A",
        },
        {
          field: "cardealer_notes_2",
          headerName: "Bemerkung Herstellersystem B",
        },
        {
          field: "cardealer_notes_3",
          headerName: "Bemerkung Herstellersystem C",
        },
      ]
    },
    {
      headerName: 'Bestellung', children: [
        { field: "admission_service_leasing_order_id", headerName: "admission_service_leasing_order_id" },
        {
          field: "assembler_service_package_name",
          headerName: "Art der Ein- o. Aufbau Leistung",
          headerTooltip: "Einem Auftrag zugeordnete Dienstleistung für Ein- und Aufbau."
        },
        { field: "cardealer_order_cancellation_date", headerName: "cardealer_order_cancellation_date" },
        {
          field: "cardealer_order_date",
          headerName: "Produktionszeitraum",
          headerTooltip: "Datum, an dem das Fahrzeug beim Autohaus bestellt wurde.",
        },
        {
          field: "cardealer_vehicle_order_type",
          headerName: "Auftragsart",
        },
        { field: "cardealer_vehicle_order_type_code", headerName: "cardealer_vehicle_order_type_code" },
        {
          field: "cardealer_vehicle_order_type_text",
          headerName: "Auftragssegment lt. Herstellersystem",
        },
        { field: "cardealer_warranty_start_date", headerName: "cardealer_warranty_start_date" },
        { field: "cd_name_salesman", headerName: "Verkäufername" },
        { field: "cd_salesman_code", headerName: "Verkäufercode" },
        { field: "leasing_order_id", headerName: "Leasing-ID" },
        {
          field: "leasing_order_id_short",
          headerName: "Leasingvertragsnummer",
          headerTooltip: "Leasingvertragsnummer",
        },
        {
          field: "cd_self_admission_notification",
          headerName: "Selbstzulasser (101 Meldung)",
        },
        {
          field: "ma_invoice_date_ma_to_cd",
          headerName: "Rechnungsdatum lt. Hersteller",
          headerTooltip: "Rechnungsdatum Hersteller an Autohaus",
        },
        {
          field: "ma_invoice_no_ma_to_cd",
          headerName: "Rechnungsnummer lt. Hersteller",
          headerTooltip: "Rechnungsnummer des Herstellers an Autohaus",
        },
        {
          field: "production_order_id_short",
          headerName: "Hersteller Bestellnummer",
        },
        {
          field: 'production_order_transfer_target_order_id',
          headerName: 'Nachfolge Hersteller Bestellnummer',
          headerTooltip: 'Bei einer Änderung der Hersteller Bestellnummer (z.B. Opel JOB PA Nummer oder VW Kommissionsnummer) wird in dieser Spalte die neue Herstellerbestellnummer zu dieser Bestellung angezeigt.'
        },
        {
          field: "production_order_transfer_target_order_id_short",
          headerName: "Nachfolge Hersteller Bestellnummer",
          headerTooltip:
            "Bei einer Änderung der Hersteller Bestellnummer (z.B. Opel JOB PA Nummer oder VW Kommissionsnummer) wird in dieser Spalte die neue Herstellerbestellnummer zu dieser Bestellung angezeigt.",
        },
        { field: "vehicle_order_id", headerName: "vehicle_order_id" },
        { field: "ma_retail_price_net", headerName: "Unverbindliche Preisempfehlung (Netto)" }
      ]
    },
    {
      headerName: 'Datentransfer', groupId: "datentransfer", children: [
        {
          field: 'last_data_transfer',
          headerName: 'Letzte Datenübertragung',
          headerTooltip: 'Letzte Aktualisierung dieses Datensatzes.',
        },
        {
          field: 'last_data_transfer_provider',
          headerName: 'Letzter Datenlieferant',
          headerTooltip: 'Name des Systems über das Vertragdaten das letzte Mal aktualisiert wurden.'
        },
        {
          field: "last_production_order_update",
          headerName: "Letzte Daten Aktualsierung lt. Herstellersystem",
        },
      ]
    },
    {
      headerName: 'Eigene Bemerkungsfelder', groupId: "eigene_bemerkungsfelder", marryChildren: true, children: [
        {
          field: "custom_field_1",
          headerName: "Eigene Bemerkung A",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_2",
          headerName: "Eigene Bemerkung B",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_3",
          headerName: "Eigene Bemerkung C",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_4",
          headerName: "Eigene Bemerkung D",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_5",
          headerName: "Eigene Bemerkung E",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_6",
          headerName: "Eigene Bemerkung F",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_7",
          headerName: "Eigene Bemerkung G",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_8",
          headerName: "Eigene Bemerkung H",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_9",
          headerName: "Eigene Bemerkung I",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_10",
          headerName: "Eigene Bemerkung J",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_11",
          headerName: "Eigene Bemerkung K",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_12",
          headerName: "Eigene Bemerkung L",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_13",
          headerName: "Eigene Bemerkung M",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_14",
          headerName: "Eigene Bemerkung N",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_15",
          headerName: "Eigene Bemerkung O",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_16",
          headerName: "Eigene Bemerkung P",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_17",
          headerName: "Eigene Bemerkung Q",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_18",
          headerName: "Eigene Bemerkung R",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_19",
          headerName: "Eigene Bemerkung S",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_20",
          headerName: "Eigene Bemerkung T",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_21",
          headerName: "Eigene Bemerkung U",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_22",
          headerName: "Eigene Bemerkung V",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_23",
          headerName: "Eigene Bemerkung W",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_24",
          headerName: "Eigene Bemerkung X",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_25",
          headerName: "Eigene Bemerkung Y",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_26",
          headerName: "Eigene Bemerkung Z",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_27",
          headerName: "Eigene Bemerkung AA",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_28",
          headerName: "Eigene Bemerkung AB",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_29",
          headerName: "Eigene Bemerkung AC",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
        {
          field: "custom_field_30",
          headerName: "Eigene Bemerkung AD",
          headerTooltip:
            "Die Spalten Überschrift kann innerhalb Ihrer Unternehmensgruppe durch Ihren Administrator umbenannt werden.",
        },
      ]
    },
    {
      headerName: 'Fahrzeug', groupId: "fahrzeug", marryChildren: true, children: [
        { field: "vehicle_model", headerName: "Modell" },
        { field: "vehicle_model_code", headerName: "vehicle_model_code" },
        {
          field: "vehicle_brand",
          headerName: "Marke",
        },
        { field: "vehicle_model_line", headerName: "Modelllinie" },
        { field: "vehicle_model_line_code", headerName: "vehicle_model_line_code" },
        { field: "vehicle_model_normalized", headerName: "vehicle_model_normalized" },
        { field: "vehicle_color", headerName: "Farbe original" },
        { field: "vehicle_color_code", headerName: "vehicle_color_code" },
        { field: "vehicle_identification_number", headerName: "FIN" },
        { field: "vehicle_interior_color", headerName: "vehicle_interior_color" },
        { field: "vehicle_yoma_color_code", headerName: "Farbgruppe" },
        { field: "vehicle_license_certificate_part_two", headerName: "ZBII Nummer" },
        {
          field: "vehicle_fuel_type",
          headerName: "Kraftstoff Art",
          headerTooltip: "Gibt die Kraftstoffart des Antriebs an",
        },
        {
          field: "vehicle_gear_type",
          headerName: "Getriebe Art",
          headerTooltip: "Gibt die Getriebeart des Antriebs an",
        },
        { field: "vehicle_hsn", headerName: "vehicle_hsn" },
        {
          field: "vehicle_license_number",
          headerName: "Aktuelles Kfz-Kennzeichen",
          headerTooltip: "Gibt das aktuelle KFZ Kennzeichen an",
        },
        { field: "vehicle_body", headerName: "Fahrzeugtyp" },
        {
          field: "vehicle_yoma_body_code",
          headerName: "Fahrzeug Segment",
          headerTooltip: "Karosserieform",
        },
        { field: "vehicle_tsn", headerName: "vehicle_tsn" },
      ]
    },
    {
      headerName: 'Kunde', groupId: "kunde", children: [
        { field: "cardealer_customer_id", headerName: "cardealer_customer_id" },
        { field: "cardealer_customer_name", headerName: "Kundenname lt. Autohaus" },
        {
          field: "customer_invoice_note_dealer",
          headerName: "Kundenrechnungsvermerk Herstellersystem",
        },
        {
          field: "cardealer_customer_reference",
          headerName: "Kundenummer",
          headerTooltip: "Kundennummer des Kunden beim Hersteller",
        },
        {
          field: "cardealer_major_customer_number",
          headerName: "Großkundennummer",
          headerTooltip: "Großkundennummer des Kunden beim Hersteller",
        },
        {
          field: "cd_key_account_contract_no",
          headerName: "Großkundenvertragsnummer",
          headerTooltip: "Großkundenvertragsnummer des Kunden beim Hersteller",
        },
        { field: "cd_key_account_bonus_flag", headerName: "cd_key_account_bonus_flag" },
        { field: "leasing_customer_name", headerName: "Kundenname lt. Leasing" },
        {
          field: "vehicle_driver_name",
          headerName: "Fahrername",
          headerTooltip: "Name des eingetragenen Fahrers im Herstellersystem",
        },
      ]
    },
    {
      headerName: "Schnittstellen Felder", groupId: "schnittstellen_felder", children: [
        {
          field: "lc_system_field_1",
          headerName: "Leasing Schnittstellendaten 1",
          headerTooltip: "Leasing Schnitt-stellendaten 1",
        },
        {
          field: "lc_system_field_2",
          headerName: "Leasing Schnittstellendaten 2",
          headerTooltip: "Leasing Schnitt-stellendaten 2",
        },
        {
          field: "lc_system_field_3",
          headerName: "Leasing Schnittstellendaten 3",
          headerTooltip: "Leasing Schnitt-stellendaten 3",
        },
        {
          field: "lc_system_field_4",
          headerName: "Leasing Schnittstellendaten 4",
          headerTooltip: "Leasing Schnitt-stellendaten 4",
        },
        {
          field: "lc_system_field_5",
          headerName: "Leasing Schnittstellendaten 5",
          headerTooltip: "Leasing Schnitt-stellendaten 5",
        },
        {
          field: "lc_system_field_6",
          headerName: "Leasing Schnittstellendaten 6",
          headerTooltip: "Leasing Schnitt-stellendaten 6",
        },
        {
          field: "lc_system_field_7",
          headerName: "Leasing Schnittstellendaten 7",
          headerTooltip: "Leasing Schnitt-stellendaten 7",
        },
        {
          field: "lc_system_field_8",
          headerName: "Leasing Schnittstellendaten 8",
          headerTooltip: "Leasing Schnitt-stellendaten 8",
        },
        {
          field: "lc_system_field_9",
          headerName: "Leasing Schnittstellendaten 9",
          headerTooltip: "Leasing Schnitt-stellendaten 9",
        },
        {
          field: "lc_system_field_10",
          headerName: "Leasing Schnittstellendaten 10",
          headerTooltip: "Leasing Schnitt-stellendaten 10",
        },
      ]
    },
    {
      headerName: 'Autohaus-Schnittstellendaten', groupId: "autohaus_schnittstellendaten", children: [
        {
          field: "cd_system_fields.cd_system_field_1",
          headerName: "Autohaus-Schnittstellendaten 1",
        },
        {
          field: "cd_system_fields.cd_system_field_2",
          headerName: "Autohaus-Schnittstellendaten 2",
        },
        {
          field: "cd_system_fields.cd_system_field_3",
          headerName: "Autohaus-Schnittstellendaten 3",
        },
        {
          field: "cd_system_fields.cd_system_field_4",
          headerName: "Autohaus-Schnittstellendaten 4",
        },
        {
          field: "cd_system_fields.cd_system_field_5",
          headerName: "Autohaus-Schnittstellendaten 5",
        },
        {
          field: "cd_system_fields.cd_system_field_6",
          headerName: "Autohaus-Schnittstellendaten 6",
        },
        {
          field: "cd_system_fields.cd_system_field_7",
          headerName: "Autohaus-Schnittstellendaten 7",
        },
        {
          field: "cd_system_fields.cd_system_field_8",
          headerName: "Autohaus-Schnittstellendaten 8",
        },
        {
          field: "cd_system_fields.cd_system_field_9",
          headerName: "Autohaus-Schnittstellendaten 9",
        },
        {
          field: "cd_system_fields.cd_system_field_10",
          headerName: "Autohaus-Schnittstellendaten 10",
        },
        {
          field: "cd_system_fields.cd_system_field_11",
          headerName: "Autohaus-Schnittstellendaten 11",
        },
        {
          field: "cd_system_fields.cd_system_field_12",
          headerName: "Autohaus-Schnittstellendaten 12",
        },
        {
          field: "cd_system_fields.cd_system_field_13",
          headerName: "Autohaus-Schnittstellendaten 13",
        },
        {
          field: "cd_system_fields.cd_system_field_14",
          headerName: "Autohaus-Schnittstellendaten 14",
        },
        {
          field: "cd_system_fields.cd_system_field_15",
          headerName: "Autohaus-Schnittstellendaten 15",
        },
        {
          field: "cd_system_fields.cd_system_field_16",
          headerName: "Autohaus-Schnittstellendaten 16",
        },
        {
          field: "cd_system_fields.cd_system_field_17",
          headerName: "Autohaus-Schnittstellendaten 17",
        },
        {
          field: "cd_system_fields.cd_system_field_18",
          headerName: "Autohaus-Schnittstellendaten 18",
        },
        {
          field: "cd_system_fields.cd_system_field_19",
          headerName: "Autohaus-Schnittstellendaten 19",
        },
        {
          field: "cd_system_fields.cd_system_field_20",
          headerName: "Autohaus-Schnittstellendaten 20",
        },
        {
          field: "cd_system_fields.cd_system_field_21",
          headerName: "Autohaus-Schnittstellendaten 21",
        },
        {
          field: "cd_system_fields.cd_system_field_22",
          headerName: "Autohaus-Schnittstellendaten 22",
        },
        {
          field: "cd_system_fields.cd_system_field_23",
          headerName: "Autohaus-Schnittstellendaten 23",
        },
        {
          field: "cd_system_fields.cd_system_field_24",
          headerName: "Autohaus-Schnittstellendaten 24",
        },
        {
          field: "cd_system_fields.cd_system_field_25",
          headerName: "Autohaus-Schnittstellendaten 25",
        },
        {
          field: "cd_system_fields.cd_system_field_26",
          headerName: "Autohaus-Schnittstellendaten 26",
        },
        {
          field: "cd_system_fields.cd_system_field_27",
          headerName: "Autohaus-Schnittstellendaten 27",
        },
        {
          field: "cd_system_fields.cd_system_field_28",
          headerName: "Autohaus-Schnittstellendaten 28",
        },
        {
          field: "cd_system_fields.cd_system_field_29",
          headerName: "Autohaus-Schnittstellendaten 29",
        },
        {
          field: "cd_system_fields.cd_system_field_30",
          headerName: "Autohaus-Schnittstellendaten 30",
        },
      ]
    },
    {
      headerName: 'Tracking', groupId: "tracking", children: [
        { field: "admission_service_vehicle_document_entry_date", headerName: "Datum Eingang ZBII beim Zulassungsdienst" },
        { field: "admission_service_vehicle_document_exit_date", headerName: "Unterlagen Versanddatum nach erfolgter Zulassung" },
        { field: "admission_service_vehicle_document_tracking_link", headerName: "Sendungsnummer Zulassungsunterlagen" },
        { field: "admission_service_vehicle_document_tracking_number", headerName: "Sendungsnummer Zulassungsunterlagen" },
        { field: "admission_service_vehicle_registration_status", headerName: "Status Zulassung" },
        { field: "admission_service_vehicle_registration_type", headerName: "Art der Zulassung" },
        { field: "assembler_assembling_end_date", headerName: "Aufbereitung fertig" },
        { field: "assembler_order_date", headerName: "Auftrag an Aufbereiter" },
        { field: "assembler_order_status_information_text", headerName: "Aufbereitung Status" },
        { field: "current_delivery_date_planned_changelog", headerName: "current_delivery_date_planned_changelog" },
        { field: "current_shipping_organization_time", headerName: "current_shipping_organization_time" },
        { field: "date_order_cancellation_by_end_customer", headerName: "date_order_cancellation_by_end_customer" },
        {
          field: 'delivery_date_cardealer_planned_update',
          headerName: 'Letztes Anlieferungsdatum an der Zieladresse',
          headerTooltip: 'Letztes Anlieferdatum in den Vorsystemen bezogen auf die Zieladresse.'
        },
        {
          field: "yoma_production_end_date",
          headerName: "Produktion",
          headerTooltip:
            "Zeitpunkt, zu dem die Produktion des Fahrzeugs im Werk erfolgte",
        },
        {
          field: "admission_service_car_registration_date_end_customer",
          headerName: "Zulassungsdatum lt. Zulassungsdienst",
        },
        {
          field: "manufacturer_car_registration_date_end_customer",
          headerName: "Zulassungsdatum lt. Herstellersystem",
        },
        {
          field: "leasing_car_registration_date_end_customer",
          headerName: "Zulassungsdatum lt. Leasing",
        },
        {
          field: "custom_dealer_car_registration_date_end_customer",
          headerName: "Zulassungsdatum lt. DMS System",
        },
        {
          field: "yoma_state_tracking_end_node_key",
          headerName: "Ende Tracking Status",
          headerTooltip:
            "Der angegebene Status bezeichnet den Ausstiegsunkt des Trackingprozesses.",
        },
        {
          field: "yoma_state_tracking_end_date",
          headerName: "Ende Tracking Status Datum",
        },
        {
          field: "current_delivery_date_planned",
          headerName: "Letztes Anlieferungsdatum an der Zieladresse",
          headerTooltip: "Letztes Anlieferungsdatum an der Zieladresse.",
        },
        {
          field: "yoma_status",
          headerName: "Status",
          headerTooltip: "Einsteuerungsstatus auf Basis der verfügbaren Daten.",
        },
        {
          field: "leasing_order_cancellation_date",
          headerName: "Storno",
          headerTooltip: "Datum an dem die Auftragsstornierung erfolgte.",
        },
        { field: "leasing_order_time_prediction.from", headerName: "leasing_order_time_prediction.from" },
        { field: "leasing_order_time_prediction.prediction_days", headerName: "leasing_order_time_prediction.prediction_days" },
        { field: "leasing_order_time_prediction.to", headerName: "leasing_order_time_prediction.to" },
        { field: "live_tracking_delay_user", headerName: "live_tracking_delay_user" },
        { field: "live_tracking_link_date_cd", headerName: "live_tracking_link_date_cd" },
        { field: "live_tracking_link_date_cr", headerName: "live_tracking_link_date_cr" },
        { field: "live_tracking_link_date_lc", headerName: "live_tracking_link_date_lc" },
        { field: "live_tracking_link_date_os", headerName: "live_tracking_link_date_os" },
        { field: "lo_delivery_date", headerName: "lo_delivery_date" },
        { field: "lo_delivery_date_planned", headerName: "lo_delivery_date_planned" },

        {
          field: "production_order_date",
          headerName: "Bestellung beim Hersteller",
          headerTooltip: "Datum an dem das Fahrzeug beim Hersteller bestellt wurde.",
        },
        {
          field: "days_after_production_end_date",
          headerName: "Tage nach Produktionsende",
          headerTooltip: "Anzahl der Tage, die nach Produktionende vergangen sind",
        },
        {
          field: "delivery_date_cardealer_planned_at_order_time",
          headerName: "Erstes Anlieferdatum an Zieladresse",
          headerTooltip:
            "Bei Bestellung ursprünglich in den Vorsystemen angegebenes Anlieferdatum bezogen auf die Zieladresse.",
        },
        {
          field: "current_delivery_date",
          headerName: "Fahrzeug an aktueller Zieladresse eingetroffen",
          headerTooltip: "Fahrzeug an aktueller Zieladresse eingetroffen.",
        },
        {
          field: "days_after_delivery_date_cardealer",
          headerName: "Tage von Anlieferung bis Übergabe",
          headerTooltip:
            "Zeigt die Anzahl der Tage zwischen Anlieferung und Übergabe an den Kunden an.",
        },
        // { field: 'lo_entry_date_logistic_place', headerName: 'Lagereingang' },
        {
          field: "lo_entry_date_logistic_place_production",
          headerName: "Lagereingang Logistik Platz laut Herstellersystem",
          headerTooltip: "Lagereingang Logistik Platz laut Herstellersystem",
        },
        {
          field: "lo_entry_date_logistic_place_logistics",
          headerName: "Lagereingang Logistik Platz laut Logistiksystem",
          headerTooltip: "Lagereingang Logistik Platz laut Logistiksystem",
        },
        {
          headerName: 'Logistik Wunschliefertermin des Autohauses',
          field: 'lo_requested_delivery_date_cardealer_planned',
          headerTooltip: 'Logistik Wunschliefertermin des Autohauses'
        },
        {
          headerName: 'Geplanter Anlieferungstermin am Logistik Platz',
          field: 'lo_entry_date_logistic_place_planned',
          headerTooltip: 'Geplanter Anlieferungstermin am Logistik Platz'
        },
        {
          field: "organization_time_cardealer",
          headerName: "Organisationszeit des Autohauses",
          headerTooltip: "Organisations-zeit des Autohauses",
        },
        {
          field: "requested_delivery_date_end_customer_planned",
          headerName: "Wunschtermin",
          headerTooltip:
            "Letztgenannter Wunschtermin der Fahrzeugübergabe, der in den Vorsystemen erfasst wurde.",
        },
        {
          field: "current_delivery_date_end_customer_planned",
          headerName: "geplante Fahrzeugübergabe",
          headerTooltip: "Geplantes Datum der Fahrzeugübergabe laut den Vorsystemen.",
        },
        {
          field: "yoma_delivery_date_end_customer_estimate",
          headerName:
            "Von YOMA kalkuliertes voraussichtliches Übergabedatum an den Endkunden",
          headerTooltip:
            "Von YOMA kalkuliertes voraussichtliches Übergabedatum an den Endkunden",
        },
        {
          field: 'days_to_delivery_end_customer_estimate',
          headerName: 'Rechnerische Übergabe in x Tagen',
          headerTooltip: 'Restzeit bis zur berechneten Übergabe. Sie wird aus dem Anlieferdatum an der Zieladresse zuzüglich der Organisationszeit des jeweiligen Autohauses ermittelt. Die Organisationszeit ist die Summe der Zeiten für die Auslieferungsinspektion durch das Autohaus und die Zeit für die Durchführung der Zulassung.'
        },
        {
          field: "live_tracking_delay",
          headerName: "Verzögerung Tracking Link",
          headerTooltip:
            "Verzögerung in Tagen, um die die Anzeige beim Kunden/Nutzer im Tracking Link zeitlich verzögert wird. Das gilt für den konkreten Tracking-Punkt: Auslieferungsvorbereitung. Nur beim Wert „0“ wird das zeitlich korrekte Datum für die Anlieferung an der Zieladresse, welches dem Tracking-Punkt „Auslieferungsvorbereitung“ entspricht, im Live-Tracking-Link angezeigt.",
        },
        // {
        //   field: "live_tracking_link_date",
        //   headerName: "Live Tracking Link erzeugt",
        //   headerTooltip:
        //     "Datum, an dem der Live Tracking Link letztmalig erzeugt wurde",
        // },
        {
          field: "production_order_transfer_date",
          headerName: "Datum Bestellübergang",
          headerTooltip:
            "Datum, an dem der Bestellübergang aus den Vorsystemen mitgeteilt wurde. Die Ursache des Bestellübergangs ist in der Spalte 'Ereignis Bestellübergang' ersichtlich.",
        },
        {
          field: "production_order_transfer_reason",
          headerName: "Ereignis Bestellübergang",
          headerTooltip:
            "Die vom Vorsystem gemeldete Ursache für den Bestellübergang. Dies kann eine Änderung der Herstellerbestellnummer oder ein Wechsel des bestellenden Händlers sein.",
        },
        {
          field: "order_cancellation_by_end_customer",
          headerName: "Storniert durch Kunde",
          headerTooltip: "Stornierung des Kunden beim Autohaus",
        },
        {
          field: "order_cancellation_by_cardealer",
          headerName: "Storniert durch Autohaus",
          headerTooltip: "Stornierung des Autohauses gegenüber dem Hersteller",
        },
        {
          field: "written_confirmed_order_date_end_customer",
          headerName: "Auftragsbestätigungsdatum lt. Herstellersystem",
          headerTooltip: "Datum der Auftragsbestätigung an den End Kunden",
        },
        {
          field: "vehicle_document_shipping_date",
          headerName: "Briefversanddatum",
          headerTooltip: "Datum des Brief Versandes an das Autohaus",
        },
        {
          field: "vehicle_document_print_date",
          headerName: "Briefdruck Datum",
          headerTooltip: "Datum an dem die ZB II gedruckt wurde",
        },
        {
          field: "production_end_date_planned",
          headerName: "erster geplanter Produktionstermin",
          headerTooltip: "Der erste geplante Produktionstermin laut Herstellersystem",
        },
        {
          field: "production_end_date_planned_update",
          headerName: "aktueller geplanter Produktionstermin",
          headerTooltip: "Der aktuelle geplante Produktionstermi",
        },
        {
          field: "process_status_logistics",
          headerName: "Aktueller Fahrzeug Status",
          headerTooltip: "Gibt den aktuellen Fahrzeugstatus im Bestellsystem an",
        },
        {
          field: "lo_to_cd_delivery_order_date",
          headerName: "Abruf Datum Logistik",
          headerTooltip: "Abruf Datum Logistik",
        },
        {
          field: "lo_exit_date_logistics_place",
          headerName: "Ausgangsdatum Regionaler Platz Drittsysteme",
          headerTooltip: "Ausgangsdatum Regionaler Platz Drittsysteme",
        },
        {
          field: "loe_delivery_date_end_customer",
          headerName: "Eigenachse Logistiker Übergabedatum an Kunden",
        },
        {
          field: "loe_delivery_date_end_customer_planned",
          headerName: "geplantes Eigenachse Logistiker Übergabedatum an Kunden",
        },
        {
          field: "loe_delivery_status",
          headerName: "Auftragsstatus Eigenachse Logistik",
        },
        {
          field: "loe_delivery_status_date",
          headerName: "Auftragsstatus Datum Eigenachse Logistik",
        },
        { field: "loe_exit_date", headerName: "Abholdatum Eigenachse Logistiker" },
        {
          field: "loe_exit_address",
          headerName: "Abholadresse lt. Eigenachse Logistiker",
        },
        {
          field: "loe_exit_date_planned",
          headerName: "geplantes Abholdatum Eigenachse Logistiker",
        },
        {
          field: "loe_logistics_order_id",
          headerName: "Auftragsnummer Eigenachse Logistiker",
        },
        { field: "loe_logistics_partner_id", headerName: "loe_logistics_partner_id" },
        { field: "loe_logistics_system_id", headerName: "loe_logistics_system_id" },
        { field: "logistics_shipping_id", headerName: "logistics_shipping_id" },
        { field: "logistics_shipping_partner_id", headerName: "logistics_shipping_partner_id" },
        { field: "logistics_system_id", headerName: "logistics_system_id" },
        {
          field: 'yoma_pdi_start_date',
          headerName: 'Fahrzeug in Qualitätskontrolle',
          headerTooltip: 'Startdatum der Fahrzeugqualitätskontrolle im Logistikzentrum',
        },
        {
          field: 'delivery_date_cardealer_deviation',
          headerName: 'Ampelstatus',
          headerTooltip: 'Zeitabweichung zwischen dem aktualisierten Anlieferungsdatum zum erstgenannten Anlieferdatum an der Zieladresse. Die Farben markieren das Ausmaß der Abweichung. Diese können in den Tabelleneinstellungen verändert werden.'
        },
        { field: "ma_debt_collection_date", headerName: "Hersteller Inkasso Datum" },
        {
          field: "ma_gate_exit_date",
          headerName: "Werksausgangsdatum",
          headerTooltip: "Werksausgangsdatum",
        },
        { field: "process_date_logistics", headerName: "process_date_logistics" },
        { field: "production_order_cancellation_date", headerName: "production_order_cancellation_date" },
        { field: "production_order_status_code", headerName: "production_order_status_code" },
        {
          field: "production_order_time_prediction.from",
          headerName: "Fleet Prediction",
          headerTooltip:
            "Die dargestellten Werte sind eigens errechnete Prognosewerte, basierend auf ähnlichen Bestellungen.",
        },
        {
          field: "production_order_time_prediction.prediction_days",
          headerName: "production_order_time_prediction.prediction_days",
        },
        {
          field: "production_order_time_prediction.to",
          headerName: "production_order_time_prediction.to",
        },
        {
          field: "requested_delivery_date_end_customer_deviation",
          headerName: "Ampelstatus",
          headerTooltip: "Zeitabweichung von der berechneten Übergabe zum letztgenannten Wunschtermin. Die Farben markieren das Ausmaß der Abweichung. Diese können in den Tabelleneinstellungen verändert werden."
        },
        { field: "technical_approval_date", headerName: "Technisch abgenommen" },
        {
          field: "workshop_order_date",
          headerName: "Werkstattauftrag geschrieben"
        },
        { field: "yoma_state_tracking_end_value", headerName: "yoma_state_tracking_end_value" },
        { field: "vehicle_document_entry_date", headerName: "vehicle_document_entry_date" },
        { field: "vehicle_document_exit_date", headerName: "vehicle_document_exit_date" },
        { field: "vehicle_document_target_location", headerName: "vehicle_document_target_location" },
        {
          field: "ma_delivery_target_date",
          headerName: "Auslieferungsfrist des Herstellers",
        }
      ]
    },
    {
      headerName: "Verbrauch", groupId: "verbrauch", children: [
        { field: "ma_vehicle_co2_emiss_city_gramm_km", headerName: "ma_vehicle_co2_emiss_city_gramm_km" },
        { field: "ma_vehicle_co2_emiss_country_gramm_km", headerName: "ma_vehicle_co2_emiss_country_gramm_km" },
        { field: "ma_vehicle_co2_emission_nefz", headerName: "ma_vehicle_co2_emission_nefz" },
        { field: "ma_vehicle_co2_emission_wltp", headerName: "ma_vehicle_co2_emission_wltp" },
        { field: "ma_vehicle_co2_emission_wltp_extra_high", headerName: "ma_vehicle_co2_emission_wltp_extra_high" },
        { field: "ma_vehicle_co2_emission_wltp_high", headerName: "ma_vehicle_co2_emission_wltp_high" },
        { field: "ma_vehicle_co2_emission_wltp_low", headerName: "ma_vehicle_co2_emission_wltp_low" },
        { field: "ma_vehicle_co2_emission_wltp_medium", headerName: "ma_vehicle_co2_emission_wltp_medium" },
        { field: "ma_vehicle_consumption_wltp", headerName: "ma_vehicle_consumption_wltp" },
        { field: "ma_vehicle_consumption_wltp_extra_high", headerName: "ma_vehicle_consumption_wltp_extra_high" },
        { field: "ma_vehicle_consumption_wltp_high", headerName: "ma_vehicle_consumption_wltp_high" },
        { field: "ma_vehicle_consumption_wltp_low", headerName: "ma_vehicle_consumption_wltp_low" },
        { field: "ma_vehicle_consumption_wltp_medium", headerName: "ma_vehicle_consumption_wltp_medium" },

        { field: "ma_vehicle_combined_electric_range", headerName: "ma_vehicle_combined_electric_range" },
        { field: "ma_vehicle_combined_power_consumption", headerName: "ma_vehicle_combined_power_consumption" },
      ]
    },
    {
      headerName: 'YOMA Intern', groupId: "yoma_intern", children: [
        { field: "admission_service_partner_id", headerName: "admission_service_partner_id" },
        { field: "admission_service_system_id", headerName: "admission_service_system_id" },
        { field: "admission_service_vehicle_document_shipping_service_partner_id", headerName: "admission_service_vehicle_document_shipping_service_partner_id" },
        { field: "assembler_partner_id", headerName: "assembler_partner_id" },
        { field: "assembler_system_id", headerName: "assembler_system_id" },
        { field: "cardealer_sales_partner_group_id", headerName: "cardealer_sales_partner_group_id" },
        { field: "cardealer_sales_partner_id", headerName: "cardealer_sales_partner_id" },
        { field: "cardealer_shipping_id", headerName: "cardealer_shipping_id" },
        { field: "cardealer_shipping_partner_id", headerName: "cardealer_shipping_partner_id" },
        {
          field: "cardealer_id",
          headerName: "Bestellendes Autohaus ID",
          headerTooltip:
            "Hersteller oder Filialbezeichnung des bestellenden Autohauses.",
        },
        { field: 'cardealer_partner_id', headerName: 'Bestellendes Autohaus ID' },
        { field: 'cardealer_partner_group_id', headerName: 'Gruppe Bestellendes Autohaus' },
        {
          field: "cardealer_sales_id",
          headerName: "Auftragsdurchführendes Autohaus ID",
          headerTooltip:
            "Hersteller oder Filialbezeichnung des ausführenden Autohauses.",
        },
        { field: "current_shipping_id", headerName: "current_shipping_id" },
        { field: "current_shipping_partner_id", headerName: "current_shipping_partner_id" },
        { field: "custom_dealer_system_id", headerName: "custom_dealer_system_id" },
        { field: "leasing_customer_id", headerName: "Kundennummer" },
        { field: "leasing_partner_group_id_in_manufacturer_order_system", headerName: "leasing_partner_group_id_in_manufacturer_order_system" },
        { field: "leasing_partner_group_id_in_manufacturer_order_system_raw", headerName: "leasing_partner_group_id_in_manufacturer_order_system_raw" },
        { field: "lessor_id", headerName: "lessor_id" },
        { field: "lessor_partner_group_id", headerName: "lessor_partner_group_id" },
        { field: "lessor_partner_id", headerName: "lessor_partner_id" },
        { field: 'online_sales_order_id', headerName: 'online_sales_order_id' },
        { field: "online_sales_partner_id", headerName: "online_sales_partner_id" },
        {
          field: 'production_order_id',
          headerName: 'Hersteller-ID',
          headerTooltip: 'Nummer, unter der das Fahrzeug im System des Herstellers bestellt wurde'
        },
        {
          field: "production_order_transfer_target_cardealer_id",
          headerName: "Neues bestellendes Autohaus ID",
          headerTooltip:
            "Bei einem Wechsel des bestellenden Autohauses wird in dieser Spalte die ID des neuen bestellenden Autohaus zu dieser Bestellung angezeigt.",
        },
        {
          field: "traffic_light_indicator",
          headerName: "Ampelstatus",
          headerTooltip: "Zeitabweichung von der berechneten Übergabe zum letztgenannten Wunschtermin. Die Farben markieren das Ausmaß der Abweichung. Diese können in den Tabelleneinstellungen verändert werden.",
        },
        { field: "traffic_light_indicator_cd", headerName: "traffic_light_indicator_cd" },
        { field: "traffic_light_indicator_cr", headerName: "traffic_light_indicator_cr" },
        { field: "traffic_light_indicator_lc", headerName: "traffic_light_indicator_lc" },
        { field: "traffic_light_indicator_os", headerName: "traffic_light_indicator_os" },
        {
          field: "ma_vehicle_unique_data_identifier",
          headerName: "Fyon Auftragsnummer aus Herstellersystem",
        },
        { field: "manufacturer_order_link", headerName: "manufacturer_order_link" },
        { field: "manufacturer_partner_id", headerName: "manufacturer_partner_id" },
        { field: "match_hint.k", headerName: "match_hint.k" },
        { field: "match_hint.v", headerName: "match_hint.v" },
        { field: "onlinesales_partner_group_id", headerName: "onlinesales_partner_group_id" },
        { field: "ops_force_overwritable", headerName: "ops_force_overwritable" },
        { field: "ordering_partner_group_id", headerName: "ordering_partner_group_id" },
        { field: "ordering_partner_id", headerName: "ordering_partner_id" },
        { field: "production_factory_code", headerName: "Hersteller Werks Code" },
        { field: "production_factory_partner_id", headerName: "production_factory_partner_id" },
        { field: "production_order_transfer_target_cardealer_partner_id", headerName: "production_order_transfer_target_cardealer_partner_id" },
        { field: "record_changed_manually", headerName: "record_changed_manually" },
        { field: "record_corrupted", headerName: "record_corrupted" },
        {
          field: "transfer_status.cd.id",
          headerName: "transfer_status.cd.id",
        },
        {
          field: "transfer_status.cd.initial_transfer",
          headerName: "Erstanlage Bestelldatensatz",
          headerTooltip: "Datum, an dem der Bestelldatensatz initial in track+trace angelegt wurde",

        },
        {
          field: 'transfer_status.cd.last_modification',
          headerName: 'Letzte Änderung der Autohausdaten',
        },
        {
          field: 'transfer_status.cd.last_transfer',
          headerName: 'Letzter Import der Autohausdaten',
        },
        {
          field: 'transfer_status.cd.name',
          headerName: 'transfer_status.cd.name',
        },
        {
          field: 'transfer_status.lc.id',
          headerName: 'transfer_status.lc.id',
        },
        {
          field: 'transfer_status.lc.initial_transfer',
          headerName: 'transfer_status.lc.initial_transfer',
        },
        {
          field: 'transfer_status.lc.last_modification',
          headerName: 'Letzte Änderung der Leasingdaten',
        },
        {
          field: 'transfer_status.lc.last_transfer',
          headerName: 'Letzter Import der Leasingdaten',
        },
        {
          field: 'transfer_status.lc.name',
          headerName: 'transfer_status.lc.name',
        },
        { field: 'yoma_vehicle_id', headerName: 'Tracking-Nr' },
      ]
    },
    {
      headerName: 'Sonstige', groupId: "sonstige", children: [
        {
          field: "current_delivery_date_end_customer",
          headerName: "Datum der Fahrzeugübergabe",
          headerTooltip: "Datum der Fahrzeugübergabe laut den Vorsystemen",
        },
        {
          field: "loe_delivery_address",
          headerName: "Zieladresse lt. Eigenachse Logistiker",
        },
      ]
    },
  ]
}
