<div class="header-container">
  <h3 class="header-title">Data attributes</h3>
  <div style="width: 300px;">
    <nz-input-group [nzSize]="'large'" [nzSuffix]="suffixIconSearch" [nzPrefix]="clearInput">
      <input
        type="text"
        nz-input
        placeholder="Search variable"
        #input
        (keydown.enter)="setSearch(input)"
      />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <button nz-button nzType="text" (click)="setSearch(input)">
        <span nz-icon nzType="search"></span>
      </button>
    </ng-template>
    <ng-template #clearInput>
      @if(searchInputValue) {
        <button nz-button nzType="text" (click)="input.value = ''; clearSearch()">
          <span nz-icon nzType="clear"></span>
        </button>
      }
    </ng-template>
  </div>
</div>
<div class="loader" *ngIf="isLoading">
  <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading..."></nz-spin>
</div>
<nz-collapse *ngIf="!isLoading">
  @for (column of columnDefs | attrFilterBy: searchInputValue; track $index) {
    <nz-collapse-panel [nzHeader]="getPanelHeader(column)" class="custom-collapse-panel">
      <div class="panel-content">
      <nz-table
        #basicTable
        [nzData]="column.children"
        [nzBordered]="true"
        [nzSize]="'middle'"
        [nzFrontPagination]="false"
      >
        <thead>
        <tr>
          <th>
            <label nz-checkbox [(ngModel)]="column.allSelected" (ngModelChange)="toggleSelectAll(column)"></label>
          </th>
          <th>Name</th>
          <th>Variable</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>
            <label nz-checkbox [(ngModel)]="data.selected" (ngModelChange)="onRowSelect(column)">
            </label>
          </td>
          <td>{{ data.headerName }}</td>
          <td>{{ data.field }}</td>
        </tr>
        </tbody>
      </nz-table>
      </div>
    </nz-collapse-panel>
  }
</nz-collapse>
