import { HttpInterceptorFn, HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { SocialAuthService, SocialUser, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { switchMap, filter, take, from, catchError, throwError } from 'rxjs';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(SocialAuthService);

  return authService.authState.pipe(
    filter(user => !!user),
    take(1),
    switchMap((user: SocialUser) => {
      const token = user.idToken;
      if (!token) return next(req);

      const exp = getTokenExpiration(token);
      const now = Math.floor(Date.now() / 1000);

      if (exp - now < 600) {
        return from(authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)).pipe(
          switchMap(() => authService.authState),
          filter(newUser => !!newUser?.idToken),
          take(1),
          switchMap((newUser: SocialUser) => sendRequestWithToken(req, next, newUser.idToken))
        );
      }

      return sendRequestWithToken(req, next, token);
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || 403) {
        authService.signOut();
      }
      return throwError(() => error);
    })
  );
};

function sendRequestWithToken(req: HttpRequest<any>, next: HttpHandlerFn, token: string) {
  const updatedRequest = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });
  return next(updatedRequest);
}

function getTokenExpiration(token: string): number {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp || 0;
  } catch (e) {
    return 0;
  }
}
