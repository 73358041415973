import { CommonModule } from '@angular/common';
import { Component, Input, Output, OnInit, inject, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Feature } from '../../../../model/models';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { CustomersService } from '../../../../services/customers/customers.service';
import { take } from 'rxjs';

@Component({
  selector: 'customer-feature',
  standalone: true,
  imports: [CommonModule, FormsModule, NzFormModule, NzIconModule, NzInputModule, NzModalModule, NzSelectModule, NzCardModule, NzSwitchModule, NzDividerModule, NzTypographyModule, NzButtonModule, NzNotificationModule],
  templateUrl: './customer-feature.component.html',
  styleUrl: './customer-feature.component.scss'
})
export class CustomerFeatureComponent implements OnInit {
  @Input() customerFeature!: any
  @Input() customer: any
  @Output() updatedFeatures = new EventEmitter<any>();
  notification = inject(NzNotificationService)
  customersService = inject(CustomersService);
  editFeature = false
  isLoading = false
  activating = false

  ngOnInit(): void {

  }

  enableFeature() {
    this.activating = true
    let feat = {
      price: this.customerFeature.price || 0,
      renewal_period: this.customerFeature.renewal_period || 0
    }
    if(this.customerFeature.toggled_off) {
      this.customersService.addFeatureToCustomer(this.customer.pk, this.customerFeature.pk, feat).pipe(take(1)).subscribe(
        (res:any) => {
          this.updatedFeatures.emit(res);
          this.activating = false;
          this.customerFeature.toggled_off = false
          this.notification.success(
            "",
            `Feature ${this.customerFeature.name} activated`
          )
        }
      )
    } else {
      this.customersService.deleteCustomersFeature(this.customer.pk, this.customerFeature.pk).pipe(take(1)).subscribe(
        (res:any) => {
          this.updatedFeatures.emit(res);
          this.activating = false;
          this.customerFeature.toggled_off = true
          this.notification.success(
            "",
            `Feature ${this.customerFeature.name} deactivated`
          )
        }
      )
    }
  }

  edit() {
    this.editFeature = true
  }

  update() {
    this.isLoading = true
    let feat = {
      price: this.customerFeature.price || 0,
      renewal_period: this.customerFeature.renewal_period || 0
    }
    this.customersService.addFeatureToCustomer(this.customer.pk, this.customerFeature.pk, feat).pipe(take(1)).subscribe(
      (res:any) => {
        this.isLoading = false
        this.editFeature = false
        this.notification.success(
          "",
          `Feature ${this.customerFeature.name} updated`
        )
      }, error => {
        this.notification.error('Error', 'Please, Reload the page')
      }
    )
  }
}
