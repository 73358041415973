<nz-layout class="app-layout" *ngIf="user else login">
    <nz-sider class="menu-sidebar"
      nzTheme="light"
      nzCollapsible
      nzMode="inline"
      nzWidth="256px"
      nzBreakpoint="md"
      [(nzCollapsed)]="isCollapsed"
      [nzTrigger]="null">
      <div class="sidebar-logo">
         <img src="assets/logo.svg" alt="logo">
          <h1>YOMA Admintool</h1>
      </div>
      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <li nz-menu-item routerLink="/features" nzMatchRouter>
          <span nz-icon nzType="merge-cells" nzTheme="outline"></span>
          <span>Features</span>
        </li>
        <li nz-menu-item routerLink="/mapping" nzMatchRouter>
          <span nz-icon nzType="node-expand" nzTheme="outline"></span>
          <span>Mapping</span>
        </li>
        <li nz-menu-item routerLink="/tenants" nzMatchRouter>
          <span nz-icon nzType="team" nzTheme="outline"></span>
          <span>Tenants</span>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a href="{{api_docs}}" target="_blank">
            <span nz-icon nzType="read" nzTheme="outline"></span>
            <span>API Documentation</span>
        </a>
        </li>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header class="app-header">
        <div >
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
              <span class="trigger"
                 nz-icon
                 [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
              ></span>
          </span>
          <nz-space class="menu-space">
            <nz-avatar
              *nzSpaceItem
              nzIcon="user"
              nzSize="large"
              nzSrc="{{ user.photoUrl }}"
            ></nz-avatar>
            <div *nzSpaceItem class="user-menu">
              Hey {{ user.name }}
            </div>
            <button
                class="btn-header"
                *nzSpaceItem
                nz-button
                nzType="primary"
                nzDanger
                (click)="logout()"
              >
                logout
              </button>
          </nz-space>

        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
</nz-layout>
<ng-template #login>
  <div class="login-layout">
    <div class="login-logo">
      <img src="assets/logo.svg" alt="logo">
       <h1>YOMA Admintool</h1>
    </div>
    <asl-google-signin-button type="standard" size="large" class="google-login"></asl-google-signin-button>
  </div>
</ng-template>
