import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { authGuard } from './security/guards/auth.guard';
import { GroupsComponent } from './components/groups/groups.component';
import { DataProviderSystemsComponent } from './components/data-provider-systems/data-provider-systems.component';
import {FeaturesComponent} from "./components/features/features.component";
import {MappingComponent} from "./components/mapping/mapping.component";
import {CustomersComponent} from "./components/customers/customers.component";
import { CustomerComponent } from './components/customers/customer/customer.component';

export const routes: Routes = [
    {path: 'login', component: LoginComponent},
    { path: '', component: DashboardComponent,
        children: [
            { path: 'groups', component: GroupsComponent, canActivate: [authGuard] },
            { path: 'dataproviders', component: DataProviderSystemsComponent, canActivate: [authGuard] },
            { path: 'features', component: FeaturesComponent, canActivate: [authGuard] },
            { path: 'mapping', component: MappingComponent, canActivate: [authGuard] },
            { path: 'tenants', component: CustomersComponent, canActivate: [authGuard] },
            { path: 'tenants/:id', component: CustomerComponent, canActivate: [authGuard] },
            { path: 'tenants/create', component: CustomerComponent, canActivate: [authGuard] },
        ],
        canActivate: [authGuard]
    },
    { path: '**', redirectTo: '' }
];
