<div class="container">
<!--  <h1 style="padding: 30px 0 0 30px">Leads</h1>-->
  <div class="loader" *ngIf="isLoading">
    <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading leads..."></nz-spin>
  </div>
  <nz-table
    style="width: 100%; padding: 30px"
    *ngIf="!isLoading"
    [nzData]="users"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzSize]="'small'"
  >
    <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Created at</th>
      <th>Last visit</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td>{{ user.name }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.created_at }}</td>
      <td>{{ user.last_visit }}</td>
    </tr>
    </tbody>
  </nz-table>
</div>
