import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attrFilterBy',
  standalone: true
})
export class AttrFilterByPipe implements PipeTransform {

  transform(items: any, term: string): any {
    if (!term || !items) return items;
    return AttrFilterByPipe.filter(items, term);
  }

  static filter(items: Array<{ [key: string]: any }>, term: string): Array<{ [key: string]: any }> {
    const toCompare = term.toLowerCase();
    return items.filter((element: any) =>
      element.children.some((item: any) => item.field.includes(toCompare)))
      .map((element: any) => {
        return Object.assign({}, element, { children: element.children.filter((item: any) => item.field.includes(toCompare)) })
      });
  }

}
