import { CommonModule, JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { Customer, CustomerFilter } from '../../model/models';
import { CustomersService } from '../../services/customers/customers.service';
import { take } from 'rxjs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
  selector: 'tt-filter',
  standalone: true,
  imports: [JsonPipe, CommonModule, NzIconModule, NzInputModule, FormsModule, NzButtonModule, NzSpinModule, NzDropDownModule, NzFormModule, NzSelectModule, NzTableModule, NzBreadCrumbModule, RouterLink, NzTagModule, NzTypographyModule, NzCardModule, NzSkeletonModule, NzEmptyModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnInit {
  @Input({required: true}) customer!: Customer

  message = inject(NzMessageService)
  modal = inject(NzModalService);
  cdr = inject(ChangeDetectorRef)
  customersService = inject(CustomersService)
  searchInputValue: any
  isLoading = true
  isCRUD = false
  deleteTag = ''
  selectedProp: any
  filter: CustomerFilter | undefined
  editFilter: {[key: string]: string[]} | undefined
  filterValueChanged = false
  errorMsg = ""
  vehicleResults: any[] = [];
  vehiclesPreviewLoading = false
  vehiclesResult = {} as {meta: {count: number}, data: any[]}
  vehicleBrands = new Array
  resultLoading = true
  tracker = (i: number) => i;
  nzFilterOption = (filter: any, option: any) => {
    return option.nzLabel.toLowerCase().includes(filter.toLowerCase())
  };

  compareFn = (i1: any, i2: any) => {
    if(i1 && i2) {
      return i1.value === i2.value
    }
    return false
  }

  zero = () => {return 0}

  mappings = {
    "ordering_partner_group_id": "Bestellende Gruppen ID",
    "cardealer_id": "Bestellendes Autohaus ID",
    "cardealer_sales_id": "Auftragsdurchführendes Autohaus ID",
    "cardealer_shipping_id": "Zieladresse lt. Autohaussystem ID",
    "cardealer_sales_partner_group_id": "Auftragsdurchführende Partner Gruppen ID",
    "cardealer_customer_name": "Kundenname lt. Autohaus",
    "lessor_name": "Leasinggesellschaft",
    "cd_name_salesman": "Verkäufername",
    "cd_key_account_contract_no": "Großkundenvertragsnummer",
    "cardealer_customer_reference": "Kundenummer",
    // "cd_system_fields": {
      "cd_system_field_1": "Autohaus-Schnittstellendaten 1",
      "cd_system_field_2": "Autohaus-Schnittstellendaten 2",
      "cd_system_field_3": "Autohaus-Schnittstellendaten 3",
      "cd_system_field_4": "Autohaus-Schnittstellendaten 4",
      "cd_system_field_5": "Autohaus-Schnittstellendaten 5",
      "cd_system_field_6": "Autohaus-Schnittstellendaten 6",
      "cd_system_field_7": "Autohaus-Schnittstellendaten 7",
      "cd_system_field_8": "Autohaus-Schnittstellendaten 8",
      "cd_system_field_9": "Autohaus-Schnittstellendaten 9",
      "cd_system_field_10": "Autohaus-Schnittstellendaten 10",
      "cd_system_field_11": "Autohaus-Schnittstellendaten 11",
      "cd_system_field_12": "Autohaus-Schnittstellendaten 12",
      "cd_system_field_13": "Autohaus-Schnittstellendaten 13",
      "cd_system_field_14": "Autohaus-Schnittstellendaten 14",
      "cd_system_field_15": "Autohaus-Schnittstellendaten 15",
      "cd_system_field_16": "Autohaus-Schnittstellendaten 16",
      "cd_system_field_17": "Autohaus-Schnittstellendaten 17",
      "cd_system_field_18": "Autohaus-Schnittstellendaten 18",
      "cd_system_field_19": "Autohaus-Schnittstellendaten 19",
      "cd_system_field_20": "Autohaus-Schnittstellendaten 20",
      "cd_system_field_21": "Autohaus-Schnittstellendaten 21",
      "cd_system_field_22": "Autohaus-Schnittstellendaten 22",
      "cd_system_field_23": "Autohaus-Schnittstellendaten 23",
      "cd_system_field_24": "Autohaus-Schnittstellendaten 24",
      "cd_system_field_25": "Autohaus-Schnittstellendaten 25",
      "cd_system_field_26": "Autohaus-Schnittstellendaten 26",
      "cd_system_field_27": "Autohaus-Schnittstellendaten 27",
      "cd_system_field_28": "Autohaus-Schnittstellendaten 28",
      "cd_system_field_29": "Autohaus-Schnittstellendaten 29",
      "cd_system_field_30": "Autohaus-Schnittstellendaten 30",
    // },
    // "custom_fields": {
      "custom_fields_1": "Eigene Bemerkung 1",
    // },
    "lc_system_field_1": "Leasing Schnittstellendaten 1",
    "lc_system_field_2": "Leasing Schnittstellendaten 2",
    "lc_system_field_3": "Leasing Schnittstellendaten 3",
    "lc_system_field_4": "Leasing Schnittstellendaten 4",
    "lc_system_field_5": "Leasing Schnittstellendaten 5",
    "lc_system_field_6": "Leasing Schnittstellendaten 6",
    "lc_system_field_7": "Leasing Schnittstellendaten 7",
    "lc_system_field_8": "Leasing Schnittstellendaten 8",
    "lc_system_field_9": "Leasing Schnittstellendaten 9",
    "lc_system_field_10": "Leasing Schnittstellendaten 10",
  } as {[key: string]: string}

  ngOnInit(): void {
    this.customersService.getFilters(this.customer.pk).pipe(take(1))
    .subscribe(res => {
      console.log("called");
      this.filter = res
      if(Object.keys(this.filter!).length != 0) {
        this.editFilter = structuredClone(this.filter?.filter)
        this.getVehiclesPreview();
      } else {
        this.editFilter = {}
      }
      this.isLoading = false
    })
  }

  getVehiclesPreview(){
    this.vehiclesPreviewLoading = true
    this.customersService.getFilterPreview(this.customer.pk).subscribe(res => {
      console.log("Customer Preview",res);
      this.vehicleResults = res.data
      this.vehiclesPreviewLoading = false
    })
  }

  createFilterModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    let nzTitle = 'Filter hinzufügen'
    const modal: NzModalRef = this.modal.create({
      nzTitle: nzTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
    });
  }

  saveFilter(ref: NzModalRef) {
    this.isCRUD = true
    this.customersService.setFilter(this.customer.pk, {filter: this.editFilter}).pipe(take(1))
      .subscribe(res => {
        this.filter = res
        this.editFilter = structuredClone(this.filter?.filter)
        this.isCRUD = false
        this.selectedProp = undefined
        ref.close()
        this.message.success('Filter werden erfolgreich aktualisiert')
        this.getVehiclesPreview()
      })
  }

  deleteFilter(event: Event, value: any, key?: string,) {
    event.preventDefault()
    event.stopPropagation()
    if (this.editFilter) {
      if(key) {
        this.deleteTag = value
        this.editFilter[key] = this.editFilter[key].filter((element: string) => element !== value)
        if (this.editFilter[key].length == 0) {
          delete this.editFilter[key]
        }
        if(Object.keys(this.editFilter).length == 0) {
          this.customersService.deleteFilter(this.customer.pk).pipe(take(1)).subscribe({
            next:(res) => {
              if (res.status == '204') {
                this.filter = {} as CustomerFilter
                this.deleteTag = ''
                this.message.success('Filter werden erfolgreich aktualisiert')
              }
              this.getVehiclesPreview()
            }
          })
        } else {
          this.customersService.setFilter(this.customer.pk, {filter: this.editFilter}).pipe(take(1))
          .subscribe(res => {
            this.deleteTag = ''
            this.filter = res
            this.editFilter = structuredClone(this.filter?.filter)
            this.message.success('Filter werden erfolgreich aktualisiert')
            this.getVehiclesPreview()
          })
        }

      } else {
        this.editFilter[this.selectedProp.key] = this.editFilter[this.selectedProp.key].filter((element: string) => element !== value)
      }
    }
  }

  closeModal(ref: NzModalRef) {
    this.editFilter = structuredClone(this.filter?.filter)
    this.selectedProp = undefined
    ref.destroy()
  }

  addFilterValue(input: HTMLInputElement) {
    this.editFilter![this.selectedProp.key].push(input.value)
    input.value = ''
  }

  selectFilterProp(e: any) {
    if(this.editFilter && !this.editFilter.hasOwnProperty(e.key)) {
      this.editFilter[e.key] = []
    }
  }

  log(e?: any) {
  }

  isDisabled() {
    if(this.selectedProp && this.editFilter![this.selectedProp.key].length != 0) {
      return false
    }
    return true
  }

  emptyFilter() {
    return (this.filter && Object.keys(this.filter).length == 0)
  }

  text2Slug(value: string): string {
    return value ? value.toLowerCase().replace(/ /g, '-') : ""
  }

}
