import { Component, Input, OnInit, Pipe, PipeTransform, inject } from '@angular/core';
import { Customer, Feature } from '../../../model/models';
import { CustomersService } from '../../../services/customers/customers.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CustomerFeatureComponent } from './customer-feature/customer-feature.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Pipe({
  name: 'featureName',
  standalone: true
})
export class FeatureNamePipe implements PipeTransform {
  transform(featureId: string, featureList: any): any {
    let elem = featureList.find((elem: any) => elem.pk == featureId).name;
    if (elem) { return elem }
    return
  }
}

@Component({
  selector: 'customer-features',
  standalone: true,
  imports: [CommonModule, FormsModule, NzFormModule, NzIconModule, NzInputModule, NzModalModule, NzSelectModule, NzCardModule, FeatureNamePipe, NzSwitchModule, NzDividerModule, NzTypographyModule, NzButtonModule, CustomerFeatureComponent, NzSpinModule],
  templateUrl: './customer-features.component.html',
  styleUrl: './customer-features.component.scss'
})
export class CustomerFeaturesComponent implements OnInit {
  @Input() customer!: Customer
  customersService = inject(CustomersService);
  features = new Array<Feature>()
  allFeatures = new Array()
  notificationService = inject(NzNotificationService)
  isLoading = true

  ngOnInit(): void {
    this.customersService.getCustomersFeatures(this.customer.pk).subscribe(res => {
      this.allFeatures = res;
      this.isLoading = false;
    }, error => {
      this.notificationService.error('Error', 'Please, Reload the page')
    })
  }

  updateFeatures(updatedFeatures: any){
    this.allFeatures = updatedFeatures;
  }
}
